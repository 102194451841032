// @flow

import React from 'react'
import type { Node } from 'react'
import { map } from 'ramda'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import { Heading1, BodyText } from '../components/typography'

import { renderPolaroidImage } from '../helpers/contentfulPageImageRenderer'
import type { PolaroidImage as PolaroidImageType } from '../types'

type QueryResponse = {|
  indulgencesPage: {
    images: PolaroidImageType[],
  },
|}

type IndulgencesContentProps = {
  images: PolaroidImageType[],
}

function IndulgencesContent({ images }: IndulgencesContentProps) {
  const polaroidImages = map(renderPolaroidImage, images)
  const renderImages =
    images.length > 0 ? <div id="gallery">{polaroidImages}</div> : null
  return (
    <Layout>
      <div className="bg-purple text-white">
        <div className="pt-12 pb-2 md:pt-16 px-8">
          <div className="w-full md:max-w-md md:mx-auto">
            <div className="mb-6">
              <Heading1 isTitle className="mb-3">
                Indulgences
              </Heading1>
              <BodyText className="text-purple-lighter leading-tight">
                Here at Paradise Point one never runs out of things to do—the
                beach, the shopping, and the night-life. We like to think that
                we offer our guests indulgences—special touches and fine details
                that the competition just doesn’t think about. We love to go
                that extra mile, so you feel like you are at home.
              </BodyText>
            </div>
            {renderImages}
          </div>
        </div>
      </div>
    </Layout>
  )
}

function IndulgencesPage(): Node {
  const data: QueryResponse = useStaticQuery(graphql`
    query IndulgencesPageQuery {
      indulgencesPage: contentfulPage(slug: { eq: "Indulgences" }) {
        images {
          id
          title
          description
          image: fixed(width: 500, quality: 80) {
            src
          }
        }
      }
    }
  `)

  return <IndulgencesContent images={data.indulgencesPage.images} />
}

export default IndulgencesPage
